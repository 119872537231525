$color-blue: #2a285e;
$color-blue-light: #8684a3;
$color-orange: #de4d00;
$color-orange-light: #f27838;
$color-blue-dark: #222a40;
$color-green: #3ce4bb;
$color-green-light: #9efde7;
$color-white: #ffffff;
$color-red: #ff3933;
$color-gray: #cccccc;
$color-white-dark: #f2f2f2;
$color-gray-dark: #6b6b6b;

$color-text: #4a4a4a;
$color-text-light: #7d7d7d;
