@import "awesome/fontawesome";
@import "Fonts";
@import "Colores";
@import "bootstrap";
@import "Normalize";
@import "Responsive";
@import "Dashboard";
@import "Main";
@import "Login";
@import "Buttons";
@import "Nav";
@import "Header";
@import "Grids";
@import "Home";
@import "Form";
@import "Table";
@import "Loading";
@import "Modal";
@import "Task";
@import "Planner";
