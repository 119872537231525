.module-planner {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  @media screen and (max-width: 1200px) {
    overflow: auto;
  }
  .header--search {
    position: absolute;
    top: -64px;
    display: flex;
    z-index: 5;
    border: 0;
    width: 70%;
    align-items: center;
    .box-header {
      background-color: $color-white-dark;
      padding: 0 10px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      .react-datepicker-wrapper {
        padding: 0;
        .react-datepicker__input-container {
          padding: 0;
        }
      }
      input {
        background: transparent;
        border: 0;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        padding: 8px 10px;
        color: $color-text;
        font-size: 14px;
      }
      i {
        color: $color-text;
      }
      @include respond-to(tablet) {
        display: none;
      }
      @include respond-to(mobile) {
        display: none;
      }
    }
  }
  /* Modulo Board encargado del costado de izquierda*/
  .module-planner--board {
    width: calc(100% - 300px);
    @media screen and (max-width: 1200px) {
      width: 100%;
      padding: 0 20px;
      min-width: 700px;
    }
    padding: 0 10px 0 20px;
    /* Modulo title encargado de los botones y titulo*/
    .module-planner--board-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h1 {
        margin: 0;
        font-size: 22px;
        color: $color-text;
      }
    }
    /* Modulo title botones estilos*/
    .module-planner--board-button {
      display: flex;
      button {
        background-color: $color-orange;
        color: $color-white;
        padding: 10px 15px;
        border-radius: 5px;
        border: solid $color-orange-light 1px;
        &:hover {
          background-color: transparent;
          border: solid $color-orange 1px;
          color: $color-text;
        }
      }
    }
  }

  /* Modulo  qye dibujo todo el calendario*/
  .module-board--container {
    display: flex;
    width: 100%;
    padding-top: 30px;
    background-color: $color-white;
    .module-board--horario-container {
      width: 70px;
      display: flex;
      flex-direction: column;
      .module-board--horario {
        width: 70px;
        height: 130px;
        position: relative;
        h5 {
          width: 100%;
          position: absolute;
          top: -11px;
          font-size: 16px;
          text-align: center;
          color: $color-text-light;
        }
      }
    }
    &.small {
      .module-board--horario-container {
        .module-board--horario {
          height: 50px;
        }
      }
    }
    .module-board--tramo-container {
      width: 14.3%;
      display: flex;
      flex-direction: column;
      .expanded-drop {
        height: 100%;
      }
      .module-board--tramo {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top: solid 1px #dbceeb;
        border-right: solid 1px #dbceeb;
        height: 130px;
        position: relative;
        padding: 2px;
        overflow: hidden;
        &:hover {
          overflow-y: auto;
        }
        &.noBorder {
          border-right: solid 0;
        }
        h5 {
          padding: 0;
          margin: 0;
        }
      }
      .module-board--task {
        background: $color-orange-light;
        width: 100%;
        border-radius: 10px;
        padding: 10px 10px;
        margin-bottom: 5px;
        position: relative;
        .drag-view-date {
          text-align: center;
          font-size: 14px;
          position: absolute;
          left: 5px;
          top: -2px;
          display: block;
          &:hover {
            color: red;
          }
        }
        .drag-view-task {
          text-align: center;
          font-size: 14px;
          position: absolute;
          right: 5px;
          top: -2px;
          display: block;
          &:hover {
            color: red;
          }
        }
        &.state-1,
        &.state-2,
        &.state-4,
        &.state-gray {
          background-color: #e8e9e9;
          border: solid 1px #7c7c7c;
          h3,
          span,
          h4 {
            color: #7c7c7c;
          }
        }
        &.state-6,
        &.state-3,
        &.state-red {
          background-color: #f2c1b6;
          border: solid 1px #a84343;
          h3,
          span,
          h4 {
            color: #a84343;
          }
        }

        &.state-5,
        &.state-yellow {
          background-color: #f7e9cc;
          border: solid 1px #c5b801;
          h3,
          span,
          h4 {
            color: #c5b801;
          }
        }
        &.state-7,
        &.state-green {
          background-color: #dcf7cc;
          border: solid 1px #2c7922;
          h3,
          span,
          h4 {
            color: #2c7922;
          }
        }
        h3,
        h4 {
          text-align: center;
          width: 100%;
          color: $color-white;
          font-size: 12px;
          margin: 0;
        }
        h4 {
          margin-top: 5px;
        }
      }
    }
    &.small {
      .module-board--tramo-container {
        .module-board--tramo {
          height: 50px;
        }
      }
    }
  }

  /* Modulo calendar encargado del calendario y filtro*/
  .module-planner--calendar {
    padding: 0 20px 0 10px;
    width: 300px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1200px) {
      width: 100%;
      min-width: 700px;
      padding: 0 20px;
      margin-top: 20px;
    }
    .module-planner--filter {
      width: 100%;
      margin-top: 20px;
      h3 {
        color: $color-white;
        font-size: 16px;
        padding: 10px;
        background-color: $color-orange;
        width: 100%;
        margin: 0;
      }
    }
    .module-planner--filter-check {
      padding: 10px;
      background-color: $color-white;
    }
  }

  /* Estilo del calendario*/
  .react-calendar {
    width: 100%;
  }
  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    background: $color-orange !important;
    color: $color-white;
    outline-color: 0;
    outline: 0;
    &:hover,
    &:active {
      background: $color-orange-light !important;
      outline-color: 0;
      outline: 0;
    }
  }
  .react-calendar__tile--now {
    background-color: $color-white;
  }
  .react-calendar__navigation {
    background: $color-orange;
    button {
      color: $color-white;
      &:hover {
        outline-color: 0;
        outline: 0;
        background: $color-orange-light !important;
      }
    }
  }
  .module-board--loading {
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    i {
      color: $color-orange;
      font-size: 25px;
    }
  }
}

/* Estilo del checkbox*/
.container-check {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .checkmark-label {
    margin: 0;
    color: $color-text;
    padding: 0;
    font-size: 15px;
  }
}

/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-check:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark {
  background-color: $color-orange;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 9px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.module-board {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  .module-board--days {
    width: 100%;
    border-top: solid 2px $color-orange;
    border-bottom: solid 1px $color-orange;
    display: flex;
    background-color: $color-white;
    top: 0;
    z-index: 1;
    position: -webkit-sticky;
    position: sticky;
    .module-board--without-day {
      width: 70px;
    }
    .module-board--with-day {
      width: 14.3%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 5px 0;
      h4,
      h5 {
        font-size: 22px;
        color: $color-text;
        width: 100%;
        padding: 0;
        margin: 0;
        text-align: center;
      }
    }
  }
}
