.grids--container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @include respond-to(mobile) {
    flex-wrap: wrap;
  }
  .grid-loading {
    width: 100%;
    min-height: calc(100vh - 180px);
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      color: #ede8ea;
      font-size: 30px;
    }
  }

  .grid-3 {
    box-sizing: border-box;
    width: calc(33% - 16px);
    background: #222a40;
    border-radius: 5px;
    padding: 1rem;
    margin: 0 8px;
    @include respond-to(mobile) {
      width: 100%;
    }
  }
  .grid-1 {
    box-sizing: border-box;
    width: calc(100% - 16px);
    background: #222a40;
    border-radius: 5px;
    padding: 1rem;
    margin: 0 8px;
    &.no-color {
      background: transparent;
      padding: 0 1rem;
    }
  }
}
