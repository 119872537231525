/* Button main */
.btn-main {
  color: #ffffff;
  background-color: $color-orange;
  border-color: $color-orange-light;
  a {
    color: #fff;
    text-decoration: none;
  }
}

.btn-main:hover,
.btn-main:focus,
.btn-main:active,
.btn-main.active,
.open .dropdown-toggle.btn-main {
  color: #ffffff;
  background-color: $color-orange-light;
  border-color: $color-orange;
}
