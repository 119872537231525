.modal_down {
  color: $color-text-light;
  strong {
    color: $color-text;
  }
}

.modal_icon {
  font-size: 60px;
  text-align: center;
}
