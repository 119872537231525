.container--login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: $color-orange;
  .container--dataon {
    margin-top: 20px;
    justify-self: flex-end;
    h4 {
      text-align: center;
      color: $color-white;
      margin: 0 0 3px 0;
      font-size: 12px;
    }
  }
  .module--login {
    padding: 0;
    margin: 0 auto;
    height: 80%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    background: $color-white;
    @include respond-to(mobile) {
      justify-content: center;
      flex-wrap: wrap;
      align-content: center;
    }
    @include respond-to(tablet) {
      justify-content: center;
      flex-wrap: wrap;
      align-content: center;
    }

    .module--loginImg {
      width: 50%;
      background: $color-white;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      background-image: url("../images/bienvenida.png");
      background-size: cover;
      background-position: center;
      @include respond-to(mobile) {
        display: none;
        img {
          max-width: 200px;
          width: 100%;
        }
      }
      @include respond-to(tablet) {
        display: none;
        img {
          max-width: 200px;
          width: 100%;
        }
      }
    }
    .module--loginBox {
      width: 50%;
      overflow: hidden;
      display: flex;
      background: $color-white;
      height: 100%;
      align-items: center;
      justify-content: center;
      @include respond-to(mobile) {
        width: 100%;
        height: auto;
      }
      @include respond-to(tablet) {
        width: 100%;
        height: auto;
      }
    }
    .module--loginForm {
      width: 70%;
      background: $color-white;
      padding: 3em 2.5em;
      border-radius: 5px;
      h1 {
        text-align: center;
        color: $color-blue;
        font-size: 20px;
        margin: 0 0 0 0;
        font-weight: bold;
      }
      h2 {
        text-align: center;
        color: $color-blue;
        font-size: 20px;
        margin: 0 0 1rem 0;
        font-weight: bold;
        margin-bottom: 50px;
      }
      p {
        font-size: 0.875em;
        color: $color-white;
        margin: 0 0 2rem 0;
      }
      label {
        color: $color-white;
        font-size: 0.875em;
      }
      .form-control {
        color: $color-blue;
        background: $color-white;
        border-radius: 10px;
        padding: 20px 10px;
        outline-style: none;
        font-size: 14px;
      }
      .recover--password {
        text-align: center;
        display: block;
        font-size: 12px;
        color: $color-blue;
        cursor: pointer;
        &:hover {
          color: $color-blue-light;
        }
      }
      .recover--line {
        text-align: center;
        display: block;
        width: 30px;
        height: 5px;
        margin: auto;
        margin-top: 20px;
        background-color: $color-gray-dark;
      }
    }
    .module--loginForm_boxButton {
      width: 100%;
      display: flex;
      margin-top: 2.5em;
      button {
        border-radius: 10px;
        width: 100%;
        font-size: 14px;
        text-transform: uppercase;
        padding: 12px;
      }
    }
  }
}
