.module-home {
  display: flex;
  width: 100%;
  padding: 50px 30px 10px 30px;
  flex-wrap: wrap;

  .module-home--text {
    width: 80%;
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
    .module-home--text--banner {
      background: $color-orange;
      border-radius: 2px;
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
    }
    .container-box1 {
      width: 100%;
      display: flex;
      margin-top: 100px;
      justify-content: space-between;
      @media screen and (max-width: 1450px) {
        margin-top: 60px;
      }
      @media screen and (max-width: 1320px) {
        margin-top: 10px;
      }
      .home-box1 {
        width: 49.5%;
        padding: 20px;
        background: $color-white;
      }
    }
    .container-box2 {
      @media screen and (max-width: 400px) {
        display: none;
      }
    }
    .container-box3 {
      @media screen and (max-width: 400px) {
        width: 100%;
      }
    }

    .big-container {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      @media screen and (max-width: 700px) {
        display: none;
      }
      img {
        max-width: 500px;
        @media screen and (max-width: 1450px) {
          max-width: 400px;
        }
        @media screen and (max-width: 1320px) {
          max-width: 300px;
        }
      }
    }
  }
  h1 {
    margin: 0 0 0 0;
    color: $color-white;
    @media screen and (max-width: 400px) {
      text-align: center;
      width: 100%;
    }
  }
  h2 {
    font-size: 20px;
    margin: 0 0 40px 0;
    color: $color-text-light;
  }
  p {
    color: $color-text;
  }
  .module-home--img {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $color-white;
    @media screen and (max-width: 1200px) {
      display: none;
    }
    @include respond-to(tablet) {
      align-items: center;
      margin-top: 10px;
    }
    .qr {
      width: 100%;
      max-width: 250px;
    }
    .my--app {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 10px;
      justify-content: center;
      margin-bottom: 30px;
      div {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .logo--mini {
        width: 50px;
        height: 50px;
        border-radius: 10px;
      }
      .logo--google {
        width: 100%;
        max-width: 180px;
        margin-top: 20px;
      }
    }
    p {
      color: $color-text-light;
      width: 100%;
      font-size: 14px;
      text-align: center;
      padding: 0 20px;
    }
    .logo--container {
      width: 90%;
    }
  }
}
